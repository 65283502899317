import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { GoogleMap, Marker, useLoadScript, InfoWindow } from '@react-google-maps/api';
import WorkTypeFilter from "../components/Filters/WorkTypeFilter";
import CountryFilter from "../components/Filters/CountryFilter";
import { useNavigate } from "react-router-dom";
import loader from '../assets/loader.svg'

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const center = {
  lat: 37.7749, // Default center (San Francisco)
  lng: -122.4194,
};

const markers = [
  { id: 1, position: { lat: 37.7749, lng: -122.4194 }, title: 'San Francisco', info: 'This is San Francisco!' },
  { id: 2, position: { lat: 34.0522, lng: -118.2437 }, title: 'Los Angeles', info: 'This is Los Angeles!' },
  { id: 3, position: { lat: 36.1699, lng: -115.1398 }, title: 'Las Vegas', info: 'This is Las Vegas!' },
];
function Map() {

  const navigate = useNavigate();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_ACCESS_TOKEN,
  });
  const [selectedMarker, setSelectedMarker] = useState(null);

  const handleCountrySelect = (countryCode) => {
    console.log('Selected country code:', countryCode);
  };
  const handleWorkTypeSelect = (workType) => {
    console.log('Selected work type:', workType);
  }
  return (
    <>
      <Header />

      <div className="bg-primary pt-5 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-auto bg-primary mb-3">
              <p className="text-white point-22">Bid Opportunities</p>
              <h1 className="text-secondary fw-boldest point-42">DOT</h1>

              <div>
                <span className="text-white fw-bolder text-uppercase point-16" style={{ cursor: 'pointer' }} onClick={() => navigate('/dot', { state: { data: 0 } })}>Proposals</span>
                <span className="point-16 fw-bolder text-danger mx-2">&nbsp;|&nbsp;</span>
                <span className="text-secondary fw-bolder text-uppercase point-16" style={{ cursor: 'pointer' }} onClick={() => navigate('/dot', { state: { data: 1 } })}>Bid Matching</span>
                <span className="point-16 fw-bolder text-danger">&nbsp;|&nbsp;</span>
                <span className="text-secondary fw-bolder text-uppercase point-16" style={{ cursor: 'pointer' }} onClick={() => navigate('/dot', { state: { data: 2 } })}>Agency Information</span>
              </div>
            </div>
            <div className="col text-end">
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="clearfix mb-2">
                <span className="me-3 point-16">
                  <span className="text-secondary fw-bolder text-uppercase point-16 me-2 ">Bidders List</span>

                  <span className="point-16 fw-bolder text-uppercase text-secondary me-2 ">As Read Bids</span>

                  <span className="text-secondary fw-bolder text-uppercase point-16">Notice to Contractors</span>

                  <br className="d-block d-md-none" />
                  <span className="float-md-end">
                    <span className="point-12 text-black-50 fw-light  text-secondary"><span className="point-18 text-secondary"><i className="bi bi-star"></i></span>
                      <span className="text-uppercase fw-bold text-secondary">&nbsp;Save to Dashboard</span></span>
                  </span>
                  <br className="d-block d-md-none" />
                  <span className="float-md-end me-3 point-16">
                    <span className="text-secondary fw-bolder text-uppercase point-16">View as:</span>
                    <span className="point-16 fw-bolder text-uppercase text-secondary" style={{ cursor: 'pointer' }} onClick={() => navigate('/dot')}>&nbsp;List |&nbsp;</span>
                    <span className="text-white fw-bolder text-uppercase point-16">Map</span>
                  </span>
                </span></div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <div id="map-wrapper" className="map-wrapper vh-50 mt-n20 bg-white shadow-sm mb-5" style={{ overflow: 'hidden', height: '100%' }}>
                {isLoaded ? <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  zoom={5}
                  center={center}
                >
                  {markers.map((marker) => (
                    <Marker
                      key={marker.id}
                      position={marker.position}
                      title={marker.title}
                      onClick={() => setSelectedMarker(marker)}
                    />
                  ))}




                  {selectedMarker && (
                    <InfoWindow
                      position={selectedMarker.position}
                      onCloseClick={() => setSelectedMarker(null)}
                    >
                      <div>
                        <h3>{selectedMarker.title}</h3>
                        <p>{selectedMarker.info}</p>
                      </div>
                    </InfoWindow>
                  )}
                </GoogleMap> : <div className="loader">
                  <img src={loader} alt="loading" style={{margin: '20% 5% 6% 5%'}}/>
                </div>}
                <div id="compass-rose" className="p-4 bg-white w-25 clearfix" style={{ zIndex: 10 }}>
                  <h4 className="text-secondary text-uppercase point-18">Filter:</h4>
                  <div>
                    <WorkTypeFilter onSelectWorkType={handleWorkTypeSelect} />
                  </div>
                  <div>
                    <CountryFilter onSelectCountry={handleCountrySelect} />
                  </div>
                  <input type="date" className="form-control fw-bold bg-white rounded-0 border-bottom border border-0 border-3 border-secondary text-uppercase" aria-label="Date" aria-describedby="basic-addon1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Map;
