import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link, useLocation } from "react-router-dom";
import Pagination from "../components/Pagination";
import aldotImg from "../assets/WAH-Logo-ALDOT-Approved.png"
import WorkTypeFilter from "../components/Filters/WorkTypeFilter";

function DesignBuildPage() {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const [workTypeId, setWorkTypeId] = useState('');
  const [resetTrue, setResetTrue] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleWorkTypeSelect = (workType) => {
    setWorkTypeId(workType);
    setResetTrue(false)
  }

  return (
    <>
      <Header />
      <div className="bg-primary pt-5 pb-20">
        <div className="container">
          <div className="row">
            <div className="col col-md-auto bg-primary mb-3">
              <p className="text-white point-22">Bid Opportunities</p>
              <h1 className="text-secondary fw-boldest point-42">WAH</h1>
              <div className="dotPage">
                <ul className="nav nav-tabs align-items-center">
                  <li className="nav-item">
                    <Link
                      className={`nav-link fw-bolder text-uppercase point-16 ${activeTab === 0 ? 'text-white' : 'text-secondary'}`}
                      title="Forms"
                      onClick={() => handleTabClick(0)}
                    >
                      Forms/Information
                    </Link>
                  </li>
                  <span className="point-16 fw-bolder text-danger mx-2">|</span>
                  <li className="nav-item">
                    <Link
                      className={`nav-link fw-bolder text-uppercase point-16 ${activeTab === 1 ? 'text-white' : 'text-secondary'}`}
                      onClick={() => handleTabClick(1)}
                      to="/uplodingRequest"
                      title="uplodingRequest"
                    >
                      Advertise for sub-quotes
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="dotPage col align-self-end mb-3 text-center" style={{ marginLeft: '20%' }}>
              <ul className="nav nav-tabs align-items-center">
                <li className="nav-item">
                  <Link
                    className={`nav-link fw-bolder text-uppercase point-16 text-secondary`}
                    title="Directory"
                  >
                    DBE Directory
                  </Link>
                </li>
                <span className="point-16 fw-bolder text-danger mx-2">|</span>
                <li className="nav-item">
                  <Link
                    className={`nav-link fw-bolder text-uppercase point-16 text-secondary`}
                  >
                    B&G
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col col-md-2 text-end">
              <img src={aldotImg} width="160px" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="card w-100 mt-n20 bg-white rounded-3 shadow-sm mb-5">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-12 col-md-5">
                      <div className="input-group mb-3">
                        <span className="input-group-text text-uppercase text-secondary fw-bold border-0 bg-white rounded-0" id="basic-addon1">Filter:</span>
                        <WorkTypeFilter onSelectWorkType={handleWorkTypeSelect} />
                      </div>
                    </div>

                    <div className="col-6 col-md mb-2">
                      <div className="input-group mb-3">
                        <input type="button" className="form-control btn btn-primary text-white text-uppercase me-2 fw-bolder rounded-pill" placeholder="Search" aria-label="Search" value="Search" aria-describedby="basic-addon1" />
                        <input type="button" className="form-control btn btn-link text-uppercase me-2 text-danger text-start" placeholder="Reset" aria-label="Reset" value="Reset" aria-describedby="basic-addon1" />
                      </div>
                    </div>

                    <div className="col">
                      <div className="clearfix">
                        <span className="float-md-end">
                          <span className="point-12 text-black-50 fw-light"><span className="point-18"><i className="bi bi-star fs-6"></i></span>
                            <span className="text-uppercase fw-bold">&nbsp;Save to Dashboard</span></span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <ul className="list-group py-3">
                      <li className="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0 cursor-pointer">
                        <div className="d-md-flex justify-content-between align-items-start">
                          <div className="me-auto mb-2">
                            <span className="fw-bold point-22">Project Name Here&nbsp;</span>
                            <span className="point-22 text-black-50 fw-light mb-2"><i className="bi bi-star fs-6"></i></span>
                          </div>
                          <div className="mb-2">
                            <a className="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16">Scope of Work</a>
                          </div>
                        </div>
                        <p className="lh-lg">
                          <span className="fw-bold">Description:</span> Lorem Ipsum is simply dummy
                          text of the printing and typesetting industry.<br />
                          <span className="fw-bold">Subs/Suppliers: Opt In</span>
                          <span className="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>
                          <br className="d-block d-md-none" />
                          <span className="fw-bold">See <span className="fw-bold">0</span> Opt-in Records</span>
                          <span className="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>
                          <br className="d-block d-md-none" />
                          <span className="fw-bold">Primes: Advertise for help</span>
                          <span className="text-danger fw-bolder d-none d-md-inline-block">&nbsp;|&nbsp;</span>
                          <br className="d-block d-md-none" />
                          <span className="fw-bold"><span className="fw-bold">0</span> Prime contractors looking for
                            help</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <Pagination />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default DesignBuildPage;
