import React, { useEffect, useState } from "react";
import CountryFilter from "../components/Filters/CountryFilter";
import Pagination from "../components/Pagination";
import paginationValue from '../common/paginationValue.json';
import toast from "react-hot-toast";
import AxiosInstance from "../utils/axiosInstance";
import loader from '../assets/loader.svg'

function BidMatching() {
  const [pageLimit, setPageLimit] = useState(paginationValue.limit);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(paginationValue.currentPage);
  const [bidList, setBidList] = useState([]);
  const [showloader, setloader] = useState(false);
  const [county, setCounty] = useState('');
  const [resetTrue, setResetTrue] = useState(false);
  const [lettingDate, setLettingDate] = useState('');
  const [stateId, setStateId] = useState('');
  const [lettingDateList, setLettingDateList] = useState([]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getLettingDate();
  }, []);

  useEffect(() => {
    if (currentPage > 0) {
      getBidList();
    }
  }, [currentPage, lettingDate, stateId, county]);

  const getBidList = () => {
    const queryParams = new URLSearchParams({
      county: county,
      letting_id: lettingDate,
      stateid: stateId,
      page: currentPage,
      limit: pageLimit
    });
    setloader(true);
    const currentTime = new Date().getTime();
    AxiosInstance.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_BID_MATCHING}?currentTime=${currentTime}&${queryParams}`)
      .then(response => {
        if (response.data?.data) {
          setBidList(response.data?.data);
          setPageLimit(response.data?.limit);
          setTotalRecords(response.data?.total_records);
          setTotalPages(response.data?.total_pages);
          setCurrentPage(response.data?.page);
          setloader(false);
          toast.success(response.data?.message);
        } else {
          setBidList([]);
          setloader(false);
          toast.success(response.data?.message);
        }
      })
      .catch(error => {
        toast.error(error);
        setloader(false);
      });
  }

  const getLettingDate = () => {
    AxiosInstance.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_LETTING_DATE}`)
      .then(response => {
        if (response.data?.data) {
          setLettingDateList(response.data?.data);
          setloader(false);
        }
      })
      .catch(error => {
        console.log("in catch block:", error)
      });
  }
  const handleChange = (event) => {
    const [lettingid, stateid] = event.target.value.split("|");
    setLettingDate(lettingid);
    setStateId(stateid)
  };

  const handleReset = () => {
    setResetTrue(true);
    setStateId('');
    setLettingDate('');
    setCounty('');
  };
  const handleCountrySelect = (county) => {
    setCounty(county);
    setResetTrue(false)
  };
  return (
    <>
      <div className="row mb-2">
        <div className="col-12 col-md-auto">
          <span
            className="input-group-text ps-0 ms-0 text-uppercase text-secondary fw-bold border-0 bg-white rounded-0"
            id="basic-addon1">Filter:</span>
        </div>
        <div className="col-12 col-md">
          <div className="input-group mb-3">
            <div>
              <CountryFilter onSelectCountry={handleCountrySelect} reset={resetTrue}/>
            </div>
          </div>
        </div>
        <div className="col-6 col-md mb-2">
          <select
            className="form-select border-top-0 border-start-0 border-end-0 border-3 border-secondary rounded-0"
            aria-label="Default select example"
            value={lettingDate}
            onChange={handleChange}
          >
            <option value="" disabled>Date</option>
            {lettingDateList.length > 0 && lettingDateList?.map((options, i) => (
              <option key={i} value={`${options?.lettingid}|${options?.stateid}`}>
                {`${options?.LettingDate}(${options?.stateid})`}
              </option>
            ))}
          </select>
        </div>
        <div className="col-6 col-md mb-2">
          <div className="input-group mb-3">
            <button className="form-control btn btn-link text-uppercase me-2 text-danger text-start" onClick={() => handleReset()}>Reset</button>
          </div>
        </div>
      </div>


      <div className="">
        {!showloader ?
          <ul className="list-group py-3">
            {bidList.length && bidList ? bidList
              .map((data, i) => (
                <li className="list-group-item slideup-p py-3 mb-3 bg-light border border-1 rounded-0" key={i}>
                  <div className="d-md-flex justify-content-between align-items-start">
                    <div className="me-auto mb-2">
                      <span className="fw-bold point-22">{data?.report_name}</span>
                    </div>
                    <div className="mb-2">
                      <a
                        className="badge bg-secondary text-decoration-none text-primary rounded-pill text-uppercase py-1 px-3 fw-semibold point-16 cursor-pointer">View</a>
                    </div>
                  </div>
                </li>)) :
              <div className="d-md-flex justify-content-center">
                No Data Found.
              </div>
            }
          </ul> : <div className="loader">
            <img src={loader} alt="loading" />
          </div>}
      </div>
      <div className="col">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          pageLimit={pageLimit}
          onPageChange={handlePageChange}
        />
      </div>

    </>
  );
}

export default BidMatching;
