
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Pagination from '../Pagination';
import AxiosInstance from '../../utils/axiosInstance';
import toast from 'react-hot-toast';
import loader from '../../assets/loader.svg'
import { CSVLink } from 'react-csv';
import paginationValue from '../../common/paginationValue.json';

export default function ListOfBidModel({ open, handleClose, callId }) {
  const [search, setSearch] = useState("");
  const [pageLimit, setPageLimit] = useState(paginationValue.limit);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(paginationValue.currentPage);
  const [bidList, setBidList] = useState([]);
  const [showloader, setloader] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  useEffect(() => {
    if (callId) {
      getBidList();
    }
  }, [callId, currentPage,search]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    console.log(e.target.value)
    setSearch(e.target.value);
  };

  const getBidList = () => {
    const queryParams = new URLSearchParams({
      callid: callId,
      page: currentPage,
      limit: pageLimit,
      search:search
    });
    setloader(true);
    AxiosInstance.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_GET_BID_ITEMS}?${queryParams}`)
      .then(response => {
        if (response.data?.data) {
          setBidList(response.data?.data);
          setPageLimit(response.data?.limit);
          setTotalRecords(response.data?.total_records);
          setTotalPages(response.data?.total_pages);
          setCurrentPage(response.data?.page);
          setloader(false);
          toast.success(response.data?.message);
        } else {
          setBidList([]);
          setloader(false);
          toast.success(response.data?.message);
        }
      })
      .catch(error => {
        toast.error(error?.message);
        setloader(false);
      });
  }
  const handleRowSelection = (row) => {
    setSelectedRows((prev) =>
      prev.some((selectedRow) => selectedRow.id === row.id)
        ? prev.filter((selectedRow) => selectedRow.id !== row.id)
        : [...prev, row]
    );
  };
  const headers = [
    { label: "Call", key: "Call_Order" },
    { label: "Line", key: "Line" },
    { label: "Item", key: "Item" },
    { label: "Quantity", key: "Quantity" },
    { label: "Units", key: "Unit" },
    { label: "Description", key: "Description" },
  ];
  return (
    <Modal
      size="xl"
      show={open} onHide={handleClose} centered
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title id="example-custom-modal-styling-title centered">
          Items
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container mt-4 listBigModel">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <CSVLink
              data={selectedRows}
              headers={headers}
              filename="selected-items.csv"
              className="btn btn-primary"
              target="_blank"
            >
              Download CSV
            </CSVLink>
            <input
              type="text"
              className="form-control w-25"
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
            />
          </div>
          {!showloader ? <table className="table table-bordered table-hover centered">
            <thead className="table-dark">
              <tr>
                <th>Action</th>
                <th>Call</th>
                <th>Line</th>
                <th>Item</th>
                <th>Quantity</th>
                <th>Units</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {bidList.length > 0 ? (
                bidList.map((row, index) => (
                  <tr key={index}>
                    <td><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                      checked={selectedRows.some(
                        (selectedRow) => selectedRow.id === row.id
                      )}
                      onChange={() => handleRowSelection(row)} /></td>
                    <td>{row.Call_Order}</td>
                    <td>{row.Line}</td>
                    <td>{row.Item}</td>
                    <td>{row.Quantity}</td>
                    <td>{row.Unit}</td>
                    <td>{row.Description}</td>
                  </tr>
                ))
              ) :
                <tr>
                  <td colSpan="7" className="text-center">
                    No data found
                  </td>
                </tr>
              }
            </tbody>
          </table>
            : (<div className="loader">
              <img src={loader} alt="loading" />
            </div>)}
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          pageLimit={pageLimit}
          onPageChange={handlePageChange}
        />
      </Modal.Body>
    </Modal>
  );
};
